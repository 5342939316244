import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn, PrimaryBtn } from '@/buttons';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { Fragment, useState } from 'react';
import { MobSubNav } from './MobSubNav';
import { HomeLogo, scrollToSection } from './Nav';
import { navLinkStyles } from './NavLinkStyles';
import { getNavLink, navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexSpace, flexStart } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    background-image: ${theme.colors.darkGradient};
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
        ${flexSpace};
        margin-bottom: 40px;
    }

    @media (min-height: 720px) {
        > div {
            margin-bottom: 80px;
        }
    }
`;

const NavMenu = styled.nav<{ subDialogOpen: boolean }>`
    ${flexStart};
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    height: calc(100% - 100px);
    overflow-y: auto;

    @media (min-height: 720px) {
        height: calc(100% - 150px);
    }

    ${({ subDialogOpen }) =>
        subDialogOpen &&
        css`
            visibility: ${subDialogOpen ? 'hidden' : 'visible'};
            pointer-events: ${subDialogOpen ? 'none' : 'auto'};
            opacity: ${subDialogOpen ? 0 : 1};
        `};
`;

const BookBtn = styled(PrimaryBtn)`
    margin-top: auto;
`;

export const MobNav = () => {
    const [open, setOpen] = useState(false);
    const [subDialogOpen, setSubDialogOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const sectionScroll = (link: string) => {
        setOpen(false);
        setTimeout(() => scrollToSection(link), 100);
    };
    return width < 1360 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                <PlainBtn
                    aria-label="open navigation menu"
                    css={css`
                        margin: 0 16px 0 40px;

                        @media (min-width: 768px) {
                            order: -1;
                            margin: 0 40px 0 0;

                            > svg {
                                transform: scaleX(-1);
                            }
                        }

                        @media (min-width: 1360px) {
                            display: none;
                        }
                    `}
                >
                    <MenuIcon />
                </PlainBtn>
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="website main navigation menu"
            >
                <div>
                    <HomeLogo />
                    <DialogClose>
                        <PlainBtn data-mobile-expanded="true" aria-label="close the nav menu">
                            <CloseIcon />
                        </PlainBtn>
                    </DialogClose>
                </div>
                <NavMenu subDialogOpen={subDialogOpen}>
                    {navLinks.slice(0, -2).map((data, i) =>
                        data.links ? (
                            <MobSubNav
                                key={i}
                                text={data.text}
                                links={data.links}
                                setSubDialogOpen={setSubDialogOpen}
                            />
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                    <BookBtn onClick={() => sectionScroll(getNavLink('book appointment'))}>
                        Book Appointment
                    </BookBtn>
                </NavMenu>
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
